<template>

<div class="waiting">

	<app-icon icon="library.unavailable" class="waiting-icon" />

	<div class="waiting-info"> 

		On {{ waiting }} waiting list<template v-if="waiting !== 1">s</template>

	</div>

</div>

</template>

<script>

export default {

	props: ['waiting']

}

</script>

<style scoped>

.waiting {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 10px;
	border-bottom: 1px solid #eee;
	margin-bottom: 10px;
	margin-top: -10px;
	background-color: #ffbe7a;
}

.waiting-icon {
	font-size: 32px;
	color: #fff;
	margin-right: 10px;
	width: 40px;
}

.waiting-info {
	color: #fff;
	font-weight: 500;
	font-size: 16px;
}

</style>
